.chat-container {
  background-color: #fff;
  display: flex;
  align-items: stretch;
  .chat-sidebar {
    box-shadow: 2px 6px 11px 0px rgba(166, 166, 166, 0.8);
    margin: 10px;
    background-color: #fff;
    border-radius: 4px;
    height: 98%;
    width: 20%;
    .chat-buttons-filter {
      display: flex;
      margin: 10px;
      justify-content: space-between;
      &__btn {
        font-size: 12px;
        width: 50%;
      }
    }
    .chat-list-room {
      overflow: auto;
      height: 85%;
      .chat-user-item {
        margin-bottom: 10px;
        cursor: pointer;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #efefef;
        &--selected {
          background-color: #f2f2f2;
        }
        .ant-card-head {
          min-height: 20px;
          padding: 0 10px;
          border-bottom: 0;
          .ant-card-head-title,
          .ant-card-extra {
            padding: 0;
          }
        }
        .ant-card-body {
          padding: 5px;
        }
        &__online-status {
          &--online {
            color: #22bb48;
            height: 0.6em;
          }
          &--offline {
            color: #ccc;
          }
        }
        &__message {
          font-size: 12px;
          font-style: italic;
        }
      }
    }
  }
  .chat-message {
    width: 60%;
    height: 98%;
    background-color: #fff;
    box-shadow: 2px 6px 11px 0px rgba(166, 166, 166, 0.8);
    border-radius: 4px;
    margin: 10px;
    .chat-message-header {
      display: flex;
      padding: 10px;
      border-bottom: 2px solid #ccc;
      &__userinfo {
        flex-grow: 2;
        justify-items: center;
        justify-content: center;
        &__avatar {
          width: 80px;
          height: 80px;
          object-position: center center;
          object-fit: cover;
          border-radius: 100%;
          border: none;
          margin-right: 10px;
        }

        &__username {
          align-self: center;
          font-weight: bold;
          text-transform: capitalize;
          margin-right: 10px;
        }

        &__account-type {
          align-self: center;
          border: 1px solid #ccc;
          border-radius: 4px;
          background-color: #ccc;
          color: #000;
          display: inline-block;
          padding: 2px 5px;
          margin-right: 10px;
        }

        &_image-upload {
          display: flex;
        }
        &__txt {
        }
      }
      &__target {
        align-self: center;
        &__value {
          border: 1px solid #ccc;
          border-radius: 20px;
          padding: 5px 10px;
        }
      }
    }
    .chat-message-list {
      height: calc(100vh - 380px);
      overflow: auto;
      padding: 10px;
      .chat-message-item {
        margin-bottom: 30px;
        .image-content {
          display: flex;
          &__it-me {
            justify-content: flex-end;
          }
          &__not-me {
          }
        }
        &__chat-message-content {
          display: flex;
          align-items: center;
        }
        &__avatar {
          width: 30px;
          height: 30px;
          object-position: center center;
          object-fit: cover;
          border-radius: 100%;
          border: none;
          margin-top: 10px;
          margin-right: 10px;
        }
        &__message {
          display: flex;
          // text-align: right;
          align-items: center;
          gap: 5px;
          .text {
            border: 1px solid #ccc;
            padding: 5px 15px;
            border-radius: 20px;
            display: block;
            overflow: hidden;
            max-width: 300px;
            white-space: normal;
          }
          .time {
            font-size: 14px;
            font-style: italic;
          }
        }
        &--not-me {
          .text {
          }
        }
        &--it-me {
          justify-content: flex-end;
          .chat-message-item__message {
            flex-flow: row-reverse;
            .text {
              background-color: #0084ff;
              color: #fff;
              border: none;
            }
          }
        }
      }
    }
    .chat-message-input-box {
      padding: 10px;
      .chat-message-helper {
        margin-bottom: 10px;
      }
      .chat-input {
        display: flex;
      }
      .chat-message-input {
        resize: none;
        height: 120px;
        margin-right: 12px;
        border-radius: 4px;
      }
    }
  }
  .chat-user-info {
    width: 300px;
    height: 98%;
    background-color: #fff;
    box-shadow: 2px 6px 11px 0px rgba(79, 49, 49, 0.8);
    border-radius: 4px;
    margin: 10px;
    padding: 10px;
    padding-top: 20px;
    .row-field-info {
      display: flex;
      .title {
        width: 120px;
      }
    }
  }
  .room-container {
    border-bottom: 1px solid #000000;
    .room-active {
      padding: 10px;
      justify-content: 'center';
      display: flex;
      background-color: #8bccf1;
    }
    .room-normal {
      justify-content: 'center';
      display: flex;
      padding: 10px;
    }
    .room-flex {
      display: flex;
    }
    .room-avatar {
      width: 48px;
      height: 48px;
      border-radius: 24px;
    }
    .room-info {
      padding-left: 8px;
      .txt-time {
        font-size: 10px;
        font-style: italic;
        font-weight: bold;
        text-align: right;
      }
    }
    .button-receive-contact {
      margin-top: 8px;
    }
  }

  .chat-create-schedule {
    border-top: 0.5px solid #000000;
    padding-top: 12px;
    .list-suggest {
      display: flex;
      flex-wrap: wrap;
      .item-suggest-content {
        border: 1px solid #d9d9d9;
        border-radius: 20px;
        margin-right: 8px;
        margin-top: 8px;
        height: 24px;
        justify-content: center;
        align-items: center;
        .item-suggest-title {
          padding-left: 6px;
          padding-right: 6px;
          font-size: 12px;
        }
      }
    }
    .btn-save {
      margin-top: 10px;
    }
  }
  .chat-user-schedule {
    border-top: 0.5px solid #000000;
    padding-top: 12px;
    margin-top: 16px;
    .item-ticket {
      .content-ticket {
        justify-content: center;
        align-items: center;
        display: flex;
        border: 1px solid #000000;
        .box-date {
          justify-content: center;
          border-right: 1px solid #000000;
          .ticket-date {
            padding: 8px;
          }
        }
        .ticket-title {
          padding-left: 8px;
          padding-right: 8px;
        }
        .btn-on-off {
          height: 50px;
        }
      }
    }
  }
}

.chat-filter-input {
  width: 98%;
  margin-bottom: 10px;
}

.player-wrapper {
  position: relative;
  width: 200px;
  height: 150px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.box-text {
  // inline-size: 250px;
  // overflow-wrap: break-word;
  word-break: break-all;
}
