.txt-small {
  color: #000000;
  font-size: 12px;
  line-height: 14px;
}

.txt-normal {
  color: #000000;
  font-size: 14px;
  line-height: 17px;
}

.txt-medium {
  color: #000000;
  font-size: 18px;
  line-height: 22px;
}

.bold-500 {
  font-weight: "500";
}

.bold-700 {
  font-weight: "700";
}

.txt-bold {
  font-weight: "bold";
}
