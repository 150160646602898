.ql-editor {
  min-height: 100px !important;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.form-group {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
}
