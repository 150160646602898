.bbtoast {
    z-index: 1099;
}

.input-error {
    color: #fa5252;
    font-size: 0.875em;
    margin-top: 0.25rem;
}

.not-found-data {
    font-size: 13px;
    color: #9e9e9e;
}

/* Modal */
.modal-90w {
    width: 90%;
    max-width: none !important;
}

.modal-50w {
    width: 50%;
    max-width: none !important;
}

/* Editor */
.ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
}

.ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.ql-formats {
    margin: 5px 0px;
}

.ql-editor {
    overflow-y: scroll;
    resize: vertical;
    min-height: 350px;
    height: 700px;

    > p, h1, h2, h3, h4, h5, h6{
        padding: 8px 0px;
    }
}

.editor-errors {
    border: 1px solid #fa5252;
}

.full-page-container {
    position: fixed;
    right: 0;
    left: 280px;
    top: 68px;
    bottom: 0;
    display: flex;
    flex-direction: column;
}

.flex-none {
    flex: none;
}

.chat-container {
    border-top: 1px solid #ddd;
    height: 100%;
}

.chat-col {
    height: 100%;
}

.list-room-container {
    padding: 20px 20px 20px 0;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.list-rooms {
    height: 100%;
    overflow: auto!important;
}

.list-chats {
    height: 100%;
    overflow: auto!important;
    padding-right: 10px;
}

.room-item {
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    &:hover {
        background-color: #f6eded
    }
}

.chat-self-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.chat-self-content {
    margin-bottom: 5px;
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 8px 16px;
    max-width: 100%;
    background-color: #F37B76;
    color: #fff
}

.chat-partner-container {
    display: flex;
    align-items: flex-start;
}

.chat-partner-content {
    margin-bottom: 5px;
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 8px 16px;
    max-width: 100%;
    background-color: #fff;
    min-height: 42px;
}

.chat-partner-avatar {
    width: 42px;
    height: 42px;

    border-radius: 21px;

    margin-right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    &.has-avatar {
        background: #ffff00;

    }
}

.chat-input-container {
    display: flex;
    align-items: center;
}

.chat-input-attachments {
    cursor: pointer;
    flex: none;
    width: 40px;
    height: 40px;
    color: #1a7ad9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    margin-right: 5px;
}

.chat-input-send {
    cursor: pointer;
    flex: none;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    color: #fff;
    background-color: #1a7ad9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    padding-right: 2px;
    margin-left: 10px;
}

.content-image {
    height: 300px;
    width: auto;
}

.chat-col {
    height: 100%;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ddd;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #a0a0a0;
    border-radius: 10px;
}

.dropzone {
    min-height: unset;
    border: navajowhite;
    background: transparent;
    cursor: pointer;
}

.table thead th {
    padding: 0.75rem 0.5rem;
    font-weight: bold;
    color: #000;
    font-size: 0.875rem;
}

.dp-flex {
    display: flex;
}
.dp-block {
    display: block;
}
.dp-inline-block {
    display: inline-block;
}

.justify-content-center {
    justify-content: center;
}
.justify-content-start {
    justify-content: flex-start;
}
.justify-content-end {
    justify-content: flex-end;
}
.justify-content-space-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.pdl-10 {
    padding-left: 10px
}
.pdl-20 {
    padding-left: 20px
}

.pdr-10 {
    padding-right: 10px
}

.pdr-20 {
    padding-right: 20px
}

.pdr-30 {
    padding-right: 30px
}

.mgbt-5 {
    margin-bottom: 5px;
}

.mgbt-10 {
    margin-bottom: 10px;
}

.mgbt-20 {
    margin-bottom: 20px;
}

.mgt-5 {
    margin-top: 5px;
}

.mgt-10 {
    margin-top: 10px;
}

.mgt-15 {
    margin-top: 15px;
}

.cursor-pointer {
    cursor: pointer;
}

.table-action-btn {
    cursor: pointer;
    margin-right: 5px;
}

.custom-btn {
    padding: 10px 20px;
    border: 1px solid #ddd;
    background: #262B40;
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
    &:hover{
        background: #191c2b;
    }
}

.modal-row {
    margin-bottom: 20px;
    .input-group-text {
        border-right: 1px solid;
    }
}

.modal-label {
    width: 300px;
    flex: none;
    text-align: right;
    padding-right: 10px;
}

.modal-label-small {
    width: 150px;
    flex: none;
    text-align: right;
    padding-right: 10px;
}

.custom-react-select {
    width: 100%;
}

.editor-container {
    height: 350px;

    .ql-editor {
        height: 100%;
        min-height: unset
    }
    &.video {
        height: 250px;
    }
}

.width-100-percent {
    width: 100%;
}

.width-50-percent {
    width: 50%;
}

.bold {
    font-weight: bold;
}

.modal-sub-title {
    color: #000;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}

.survey-question-item {
    padding: 20px;
    background-color: navajowhite
}

.btn-item-survey {
    margin-left: 10px;
    font-size: 20px;
}

.custom-badge {
    padding: 3px 10px;
    line-height: 1.4;
    &.bg-secondary {
        background-color: #6c757d!important;
    }
    &.bg-success {
        background-color: #198754!important;
    }
}

.pd-20 {
    padding: 20px;
}
.mgr-5 {margin-right: 5px}
.mgr-10 {margin-right: 10px}
.mgr-15 {margin-right: 15px}
.mgl-10 {margin-left: 10px}
.d-inline-block {display: inline-block}
.pointer {cursor: pointer}
.w100p {width: 100%}
.white-box {
    background-color: white;
    &--filter {
        padding: 20px;
        margin-bottom: 20px;
    }
}
.ant-notification {
    z-index: 2000 !important;
}

.ellipsis-150 {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}
.video-segments-container {
    width: 100%;
}
.video-segments-item {
    border: 1px solid #000000;
    border-radius: 8px;
    padding: 10px 20px;
    margin-bottom: 20px;
}

.filter-option-item {
    min-width: 250px;
}
.custom-react-select.list > div {
    min-height: 43px;
    border: 0.0625rem solid #d1d7e0;
    border-radius: 0.5rem;
}
